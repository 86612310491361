.ui-map,
.angular-google-map-container {
  display: block;
  height: 400px;
}

// vector maps

.jqvmap-zoomin, .jqvmap-zoomout {
    position: absolute;
    left: 10px;
    border-radius: 2px;
    background: $darker-default;
    padding: 3px 4px;
    color: #fff;
    cursor: pointer;
    line-height: 10px;
    text-align: center;
  }

  .jqvmap-zoomin {
    top: 10px;
  }

  .jqvmap-zoomout {
    top: 30px;
  }

  .jqvmap-region {
    cursor: pointer;
  }

.jqvmap-ajax_response {
  width: 100%;
  height: 400px;
}

.jqvmap-label {
	position: absolute;
	display: none;
	border-radius: 3px;
	background: $transparent-black-8;
	color: white;
	font-size: smaller;
	padding: 3px 5px;
    z-index: 99;
}
