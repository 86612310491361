.nav-tabs {
  border: 0;
  background-color: #f5f5f5;

  >li {
    margin-bottom: 0;

    >a {
      border: 0;
      border-radius: 0;
      margin-right: 0;
      cursor: pointer;
      @include box-shadow(inset 0 -3px 0 transparent);

      &:hover {
        background-color: darken(#f5f5f5, 2%);
        border: 0;
      }
    }

    &.active {
      >a,
      >a:hover,
      >a:focus {
        box-shadow: inset 0 -3px 0 #428bca;
        border: 0;
        background-color: #e8e8e8;
      }

      &:after {
        content: '';
        position: absolute;
        left: 50%;
        margin-left: -4px;
        @include triangle(8px, $brand-primary, down);
      }
    }

    &.tabs-title {
      padding: 8px 0 0 20px;
      font-size: 16px;
      color: white;
      font-weight: $font-weight-light;
    }
  }

  &.nav-justified {
    >li {
      >a {
        border: 0;
        border-radius: 0;
      }

      &.active {
        >a,
        >a:hover,
        >a:focus {
          border: 0;
        }
      }
    }
  }
}

.tab-content {
  .tab-pane {
    padding: 15px;
  }
}

.nav-pills {
  >li>a {
    cursor: pointer;
  }
}

.nav {
  &.nav-sm {
    >li>a {
      padding: 7px 10px;
    }
  }
}

// tabs dark



.nav-tabs.tabs-dark {
  background-color: $slategray;
  >li {
    >a {
      color: $transparent-white-5;

      &:hover,
      &:focus {
        background-color: darken($slategray, 5%);
        color: $transparent-white-8;
      }
    }

    &.disabled {
      >a {
        color: $transparent-white-2;
      }
    }

    &.active {
      >a,
      >a:hover,
      >a:focus {
        background-color: darken($slategray, 10%);
        color: white;
      }
    }
  }
}



// tab container

.tab-container {
  .nav-tabs {
    background-color: white;

    >li {
      border-right: 1px solid #eaeaea;

      &:last-child {
        border-right: 0;
      }

      >a {
        color: lighten($font-color-dark, 30%);

        &:hover {
          background-color: #f5f5f5;
        }
      }

      &.active {
        >a,
        >a:hover,
        >a:focus {
          color: $font-color-dark;
        }
      }

      &.disabled {
        >a,
        >a:hover,
        >a:focus {
          color: lighten($font-color-dark, 50%);
        }
      }

    }
  }
  .tab-content {
    .tab-pane {
      background-color: white;
    }
  }
}

.tab-nopadding {
  .tab-pane {
    padding: 15px 0;
  }
}

// tab form-wizard

.tab-wizard {
  .nav-tabs {
    >li {

      >a {
        text-align: left;
        padding-left: 30px;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          @include triangle(38px, #f5f5f5, right);
          top: 1px;
          right: -18px;
          z-index: 6;
          @include transition(all .2s);
        }

        &:after {
          content: "";
          position: absolute;
          @include triangle(40px, #f5f5f5, right);
          top: 0;
          right: -20px;
          z-index: 5;
        }

        .wizard-step {
          font-size: $font-size-base;
          font-weight: $font-weight-bold;
          padding: 5px 8px;
          border-radius: 50%;
          background-color: $font-color-dark;
          color: #eaeaea;
          border: 1px solid #eaeaea;
          margin-top: -2px;
          margin-right: -10px;
          width: 26px;
          height: 26px;
          line-height: 14px;
        }
      }

      &:first-child {
        >a {
          padding-left: 15px;
        }
      }

      &:last-child {
        >a {
          &:after,
          &:before {
            display: none;
          }
          .wizard-step {
            margin-right: 0;
          }
        }
      }

      >a,
      >a:hover,
      >a:focus {
        @include box-shadow(none);
        background-color: #f5f5f5;
      }

      &:after,
      &:before {
        content: "";
        position: absolute;
        border: 0;
        width: 100%;
        height: 10px;
        background-color: $lighter-default;
        left: 0;
        margin-left: 0;
        opacity: 0;
        @include transition(opacity .8s);
      }

      &:after {
        opacity: .1;
      }

      &:before {
        background-color: $brand-primary;
        bottom: -10px;
        opacity: 1;
        @include transition(width .8s);
      }

      &.active {

        >a {
          &:before {
            border-left-color: white;
          }
          .wizard-step {
            background-color: transparent;
            color: $font-color-dark;
            border-color: $font-color-dark;
          }
        }

        >a,
        >a:hover {
          background-color: white;
        }

        &:after {
          opacity: 1;
        }
        &:before {
          opacity: 0;
          width: 0;
        }

      }

      &.disabled {

        >a {
          &:before {
            //border-left-color: white;
          }
          .wizard-step {
            background-color: transparent;
            color: #eaeaea;
            border-color: #eaeaea;
          }
        }

      }

      &.active~li {

        >a {
          &:before {
            border-left-color: white;
          }
          .wizard-step {
            background-color: transparent;
            color: #eaeaea;
            border-color: #eaeaea;
          }
        }

        >a,
        >a:hover {
          background-color: white;
        }
        &:before {
          opacity: 0;
          width: 0;
        }
      }
    }
  }
  .tab-content {
    background-color: white;
    .tab-pane {
      margin-top: 10px;
    }
    .pager.wizard {
      margin: 0;
      padding: 0 15px 15px;

      >li.disabled >.btn {
        cursor: not-allowed;
        color: #ccc;
        border-color: #eee;

        &:hover {
          background-color: white;
        }
      }

      >li.previous >.btn {
        float: left;
      }
      >li.next >.btn {
        float: right;
      }
    }
  }
}

// tab animated

.tab-animation>.tab-content {
  position: relative;

  >.tab-pane {
    @include transition(all .2s linear);

    &.active-remove {
      position: absolute;
      top: 0;
      width: 100%;
      display: block;
    }

    &.active-remove-active {
      opacity: 0;
    }

    &.active-add {
      opacity: 0;
    }
  }
}


// tabs right

.tabs-right.nav-tabs {
  text-align: right;
  >li {
    margin-right: -3px;
    float: none;
    display: inline-block;

    &:last-child {
      margin-right: 0;
    }
  }
}

//sm

@media only screen and (max-width: 768px) {
  .nav-tabs.nav-justified > li {

    a {
      margin-bottom: 0;
    }

    &:after {
      display: none;
    }

  }
  .nav-tabs > li.tabs-title {
    display: none;
  }
}

// tabs menu

.tabs-menu {
  list-style: none;
  padding: 0;
  margin: 0;

  >li {
    margin-bottom: 1px;
    position: relative;
    >a {
      display: block;
      background-color: #f7f7f7;
      padding: 10px 15px;
      color: $font-color;

      &:hover {
        color: $brand-primary;
      }
    }
    &.active {
      >a {
        background-color: $brand-primary;
        color: white;
        font-weight: $font-weight-bold;
      }
      &:after {
        content: "";
        position: absolute;
        @include triangle(12px, $brand-primary, right);
        right: -6px;
        top: 15px;
      }
    }
  }
}
