#pageloader.hide {
  display: none;
}

#pageloader.animate {
  width: 40px;
  height: 40px;
  position: fixed;
  text-align: center;
  top: 50%;
  left: 50%;
  margin:-30px 0 0 -30px;

  @include animation(loaderrotate 2.0s infinite linear);

  .dot1, .dot2 {
    width: 60%;
    height: 60%;
    display: inline-block;
    position: absolute;
    top: 0;
    background-color: $greensea;
    border-radius: 100%;

    @include animation(loaderbounce 2.0s infinite ease-in-out);
  }

  .dot2 {
    top: auto;
    bottom: 0px;
    @include animation-delay(-1.0s);
    background-color: $lightred;
  }
}


@-webkit-keyframes loaderrotate { 100% { -webkit-transform: rotate(360deg) }}
@keyframes loaderrotate { 100% { transform: rotate(360deg); -webkit-transform: rotate(360deg) }}

@-webkit-keyframes loaderbounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes loaderbounce {
  0%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
}