.InfiniteCarouselArrowIcon {
  display: inline-block;
  padding: 10px;
  border: solid #e5e5e5;
  border-width: 0 3px 3px 0;
}
ul,
ol {
  margin-top: 0;
  margin-bottom: 0px !important;
}
.item-container:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  border: 1px solid #5d9c14 !important;
  .right h4 {
    color: #5d9c14 !important;
  }
  .ant-avatar {
    border: 1px solid #5d9c14 !important;
  }
}
.not-disable:hover {
  cursor: not-allowed;
  color: gray !important;
}

.ant-breadcrumb > span {
  color: #337ab7;
}
.ant-breadcrumb:hover > span {
  color: #337ab7;
  
}

.ant-breadcrumb > span:last-child {
  color: rgba(0, 0, 0, 0.65) !important;
}
