.sidebar-main {
    a {
      &:hover,
      &:focus {
        color: $sidebar-text-active;
        text-decoration: unset;
      }
      
    }
  width: 250px;
  // height: 100vh;
  // top: 110px;
  position: fixed;
  .ant-menu-inline {
    height: 100%;
    .ant-menu-submenu-selected {
      color: $sidebar-text-color;
    }
    .ant-menu-item.ant-menu-item-selected {
      background-color: $sidebar-bg-selected;
      box-shadow: inset 4px 0 0 $sidebar-text-active;
      span {
        font-weight: 600;
        color: $sidebar-text-active;
      }
      a {
          color: $sidebar-text-active;
          text-decoration: unset;
      }
    }
    .ant-menu-submenu-title {
      &:hover {
        color: $sidebar-text-active;
        .ant-menu-submenu-arrow {
          &::after,
          &::before {
            background: $sidebar-text-active;
          }
        }
      }
    }
    .ant-menu-item {
      a {
        &:hover,
        &:focus {
          text-decoration: unset;
          span {
            color: $sidebar-text-active;
          }
        }
      }
      span {
        color: $sidebar-text-color;
        text-transform: capitalize;
      }
    }
  }
}
