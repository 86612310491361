/*!
 * animsition v3.5.2
 * http://blivesta.github.io/animsition/
 * Licensed under MIT
 * Author : blivesta
 * http://blivesta.com/
 */

.animsition,
.animsition-overlay {
  position: relative;
  opacity: 1;

  -webkit-animation-fill-mode: both;
       -o-animation-fill-mode: both;
          animation-fill-mode: both;
}
.animsition-loading {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2032%2032%22%20width%3D%2232%22%20height%3D%2232%22%20fill%3D%22%23ccc%22%3E%0A%20%20%3Cpath%20opacity%3D%22.25%22%20d%3D%22M16%200%20A16%2016%200%200%200%2016%2032%20A16%2016%200%200%200%2016%200%20M16%204%20A12%2012%200%200%201%2016%2028%20A12%2012%200%200%201%2016%204%22/%3E%0A%20%20%3Cpath%20d%3D%22M16%200%20A16%2016%200%200%201%2032%2016%20L28%2016%20A12%2012%200%200%200%2016%204z%22%3E%0A%20%20%20%20%3CanimateTransform%20attributeName%3D%22transform%22%20type%3D%22rotate%22%20from%3D%220%2016%2016%22%20to%3D%22360%2016%2016%22%20dur%3D%220.8s%22%20repeatCount%3D%22indefinite%22%20/%3E%0A%20%20%3C/path%3E%0A%3C/svg%3E%0A%0A) center center no-repeat;

  -webkit-animation-fill-mode: both;
       -o-animation-fill-mode: both;
          animation-fill-mode: both;
}
.animsition-loading:after {
  position: fixed;
  top: 50%;
  width: 100%;
  margin-top: 20px;
  font-size: 16px;
  color: #aaa;
  text-align: center;
  content: "Loading";
}
.animsition-overlay-slide {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #ddd;
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fade-in {
  -webkit-animation-name: fade-in;
       -o-animation-name: fade-in;
          animation-name: fade-in;
}
@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.fade-out {
  -webkit-animation-name: fade-out;
       -o-animation-name: fade-out;
          animation-name: fade-out;
}
@-webkit-keyframes fade-in-up {
  0% {
    opacity: 0;
    -webkit-transform: translateY(500px);
            transform: translateY(500px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@-o-keyframes fade-in-up {
  0% {
    opacity: 0;
    -o-transform: translateY(500px);
       transform: translateY(500px);
  }
  100% {
    opacity: 1;
    -o-transform: translateY(0);
       transform: translateY(0);
  }
}
@keyframes fade-in-up {
  0% {
    opacity: 0;
    -webkit-transform: translateY(500px);
         -o-transform: translateY(500px);
            transform: translateY(500px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
         -o-transform: translateY(0);
            transform: translateY(0);
  }
}
.fade-in-up {
  -webkit-animation-name: fade-in-up;
       -o-animation-name: fade-in-up;
          animation-name: fade-in-up;
}
@-webkit-keyframes fade-out-up {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-500px);
            transform: translateY(-500px);
  }
}
@-o-keyframes fade-out-up {
  0% {
    opacity: 1;
    -o-transform: translateY(0);
       transform: translateY(0);
  }
  100% {
    opacity: 0;
    -o-transform: translateY(-500px);
       transform: translateY(-500px);
  }
}
@keyframes fade-out-up {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
         -o-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-500px);
         -o-transform: translateY(-500px);
            transform: translateY(-500px);
  }
}
.fade-out-up {
  -webkit-animation-name: fade-out-up;
       -o-animation-name: fade-out-up;
          animation-name: fade-out-up;
}
@-webkit-keyframes fade-in-up-sm {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@-o-keyframes fade-in-up-sm {
  0% {
    opacity: 0;
    -o-transform: translateY(100px);
       transform: translateY(100px);
  }
  100% {
    opacity: 1;
    -o-transform: translateY(0);
       transform: translateY(0);
  }
}
@keyframes fade-in-up-sm {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100px);
         -o-transform: translateY(100px);
            transform: translateY(100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
         -o-transform: translateY(0);
            transform: translateY(0);
  }
}
.fade-in-up-sm {
  -webkit-animation-name: fade-in-up-sm;
       -o-animation-name: fade-in-up-sm;
          animation-name: fade-in-up-sm;
}
@-webkit-keyframes fade-out-up-sm {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
  }
}
@-o-keyframes fade-out-up-sm {
  0% {
    opacity: 1;
    -o-transform: translateY(0);
       transform: translateY(0);
  }
  100% {
    opacity: 0;
    -o-transform: translateY(-100px);
       transform: translateY(-100px);
  }
}
@keyframes fade-out-up-sm {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
         -o-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-100px);
         -o-transform: translateY(-100px);
            transform: translateY(-100px);
  }
}
.fade-out-up-sm {
  -webkit-animation-name: fade-out-up-sm;
       -o-animation-name: fade-out-up-sm;
          animation-name: fade-out-up-sm;
}
@-webkit-keyframes fade-in-up-lg {
  0% {
    opacity: 0;
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@-o-keyframes fade-in-up-lg {
  0% {
    opacity: 0;
    -o-transform: translateY(1000px);
       transform: translateY(1000px);
  }
  100% {
    opacity: 1;
    -o-transform: translateY(0);
       transform: translateY(0);
  }
}
@keyframes fade-in-up-lg {
  0% {
    opacity: 0;
    -webkit-transform: translateY(1000px);
         -o-transform: translateY(1000px);
            transform: translateY(1000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
         -o-transform: translateY(0);
            transform: translateY(0);
  }
}
.fade-in-up-lg {
  -webkit-animation-name: fade-in-up-lg;
       -o-animation-name: fade-in-up-lg;
          animation-name: fade-in-up-lg;
}
@-webkit-keyframes fade-out-up-lg {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
  }
}
@-o-keyframes fade-out-up-lg {
  0% {
    opacity: 1;
    -o-transform: translateY(0);
       transform: translateY(0);
  }
  100% {
    opacity: 0;
    -o-transform: translateY(-1000px);
       transform: translateY(-1000px);
  }
}
@keyframes fade-out-up-lg {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
         -o-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-1000px);
         -o-transform: translateY(-1000px);
            transform: translateY(-1000px);
  }
}
.fade-out-up-lg {
  -webkit-animation-name: fade-out-up-lg;
       -o-animation-name: fade-out-up-lg;
          animation-name: fade-out-up-lg;
}
@-webkit-keyframes fade-in-down {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-500px);
            transform: translateY(-500px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@-o-keyframes fade-in-down {
  0% {
    opacity: 0;
    -o-transform: translateY(-500px);
       transform: translateY(-500px);
  }
  100% {
    opacity: 1;
    -o-transform: translateY(0);
       transform: translateY(0);
  }
}
@keyframes fade-in-down {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-500px);
         -o-transform: translateY(-500px);
            transform: translateY(-500px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
         -o-transform: translateY(0);
            transform: translateY(0);
  }
}
.fade-in-down {
  -webkit-animation-name: fade-in-down;
       -o-animation-name: fade-in-down;
          animation-name: fade-in-down;
}
@-webkit-keyframes fade-out-down {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(500px);
            transform: translateY(500px);
  }
}
@-o-keyframes fade-out-down {
  0% {
    opacity: 1;
    -o-transform: translateY(0);
       transform: translateY(0);
  }
  100% {
    opacity: 0;
    -o-transform: translateY(500px);
       transform: translateY(500px);
  }
}
@keyframes fade-out-down {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
         -o-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(500px);
         -o-transform: translateY(500px);
            transform: translateY(500px);
  }
}
.fade-out-down {
  -webkit-animation-name: fade-out-down;
       -o-animation-name: fade-out-down;
          animation-name: fade-out-down;
}
@-webkit-keyframes fade-in-down-sm {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@-o-keyframes fade-in-down-sm {
  0% {
    opacity: 0;
    -o-transform: translateY(-100px);
       transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    -o-transform: translateY(0);
       transform: translateY(0);
  }
}
@keyframes fade-in-down-sm {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100px);
         -o-transform: translateY(-100px);
            transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
         -o-transform: translateY(0);
            transform: translateY(0);
  }
}
.fade-in-down-sm {
  -webkit-animation-name: fade-in-down-sm;
       -o-animation-name: fade-in-down-sm;
          animation-name: fade-in-down-sm;
}
@-webkit-keyframes fade-out-down-sm {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }
}
@-o-keyframes fade-out-down-sm {
  0% {
    opacity: 1;
    -o-transform: translateY(0);
       transform: translateY(0);
  }
  100% {
    opacity: 0;
    -o-transform: translateY(100px);
       transform: translateY(100px);
  }
}
@keyframes fade-out-down-sm {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
         -o-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(100px);
         -o-transform: translateY(100px);
            transform: translateY(100px);
  }
}
.fade-out-down-sm {
  -webkit-animation-name: fade-out-down-sm;
       -o-animation-name: fade-out-down-sm;
          animation-name: fade-out-down-sm;
}
@-webkit-keyframes fade-in-down-lg {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@-o-keyframes fade-in-down-lg {
  0% {
    opacity: 0;
    -o-transform: translateY(-1000px);
       transform: translateY(-1000px);
  }
  100% {
    opacity: 1;
    -o-transform: translateY(0);
       transform: translateY(0);
  }
}
@keyframes fade-in-down-lg {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-1000px);
         -o-transform: translateY(-1000px);
            transform: translateY(-1000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
         -o-transform: translateY(0);
            transform: translateY(0);
  }
}
.fade-in-down-lg {
  -webkit-animation-name: fade-in-down;
       -o-animation-name: fade-in-down;
          animation-name: fade-in-down;
}
@-webkit-keyframes fade-out-down-lg {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
  }
}
@-o-keyframes fade-out-down-lg {
  0% {
    opacity: 1;
    -o-transform: translateY(0);
       transform: translateY(0);
  }
  100% {
    opacity: 0;
    -o-transform: translateY(1000px);
       transform: translateY(1000px);
  }
}
@keyframes fade-out-down-lg {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
         -o-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(1000px);
         -o-transform: translateY(1000px);
            transform: translateY(1000px);
  }
}
.fade-out-down-lg {
  -webkit-animation-name: fade-out-down-lg;
       -o-animation-name: fade-out-down-lg;
          animation-name: fade-out-down-lg;
}
@-webkit-keyframes fade-in-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-500px);
            transform: translateX(-500px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@-o-keyframes fade-in-left {
  0% {
    opacity: 0;
    -o-transform: translateX(-500px);
       transform: translateX(-500px);
  }
  100% {
    opacity: 1;
    -o-transform: translateX(0);
       transform: translateX(0);
  }
}
@keyframes fade-in-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-500px);
         -o-transform: translateX(-500px);
            transform: translateX(-500px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
         -o-transform: translateX(0);
            transform: translateX(0);
  }
}
.fade-in-left {
  -webkit-animation-name: fade-in-left;
       -o-animation-name: fade-in-left;
          animation-name: fade-in-left;
}
@-webkit-keyframes fade-out-left {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-500px);
            transform: translateX(-500px);
  }
}
@-o-keyframes fade-out-left {
  0% {
    opacity: 1;
    -o-transform: translateX(0);
       transform: translateX(0);
  }
  100% {
    opacity: 0;
    -o-transform: translateX(-500px);
       transform: translateX(-500px);
  }
}
@keyframes fade-out-left {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
         -o-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-500px);
         -o-transform: translateX(-500px);
            transform: translateX(-500px);
  }
}
.fade-out-left {
  -webkit-animation-name: fade-out-left;
       -o-animation-name: fade-out-left;
          animation-name: fade-out-left;
}
@-webkit-keyframes fade-in-left-sm {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@-o-keyframes fade-in-left-sm {
  0% {
    opacity: 0;
    -o-transform: translateX(-100px);
       transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    -o-transform: translateX(0);
       transform: translateX(0);
  }
}
@keyframes fade-in-left-sm {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
         -o-transform: translateX(-100px);
            transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
         -o-transform: translateX(0);
            transform: translateX(0);
  }
}
.fade-in-left-sm {
  -webkit-animation-name: fade-in-left-sm;
       -o-animation-name: fade-in-left-sm;
          animation-name: fade-in-left-sm;
}
@-webkit-keyframes fade-out-left-sm {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
  }
}
@-o-keyframes fade-out-left-sm {
  0% {
    opacity: 1;
    -o-transform: translateX(0);
       transform: translateX(0);
  }
  100% {
    opacity: 0;
    -o-transform: translateX(-100px);
       transform: translateX(-100px);
  }
}
@keyframes fade-out-left-sm {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
         -o-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
         -o-transform: translateX(-100px);
            transform: translateX(-100px);
  }
}
.fade-out-left-sm {
  -webkit-animation-name: fade-out-left-sm;
       -o-animation-name: fade-out-left-sm;
          animation-name: fade-out-left-sm;
}
@-webkit-keyframes fade-in-left-lg {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-1500px);
            transform: translateX(-1500px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@-o-keyframes fade-in-left-lg {
  0% {
    opacity: 0;
    -o-transform: translateX(-1500px);
       transform: translateX(-1500px);
  }
  100% {
    opacity: 1;
    -o-transform: translateX(0);
       transform: translateX(0);
  }
}
@keyframes fade-in-left-lg {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-1500px);
         -o-transform: translateX(-1500px);
            transform: translateX(-1500px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
         -o-transform: translateX(0);
            transform: translateX(0);
  }
}
.fade-in-left-lg {
  -webkit-animation-name: fade-in-left-lg;
       -o-animation-name: fade-in-left-lg;
          animation-name: fade-in-left-lg;
}
@-webkit-keyframes fade-out-left-lg {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-1500px);
            transform: translateX(-1500px);
  }
}
@-o-keyframes fade-out-left-lg {
  0% {
    opacity: 1;
    -o-transform: translateX(0);
       transform: translateX(0);
  }
  100% {
    opacity: 0;
    -o-transform: translateX(-1500px);
       transform: translateX(-1500px);
  }
}
@keyframes fade-out-left-lg {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
         -o-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-1500px);
         -o-transform: translateX(-1500px);
            transform: translateX(-1500px);
  }
}
.fade-out-left-lg {
  -webkit-animation-name: fade-out-left-lg;
       -o-animation-name: fade-out-left-lg;
          animation-name: fade-out-left-lg;
}
@-webkit-keyframes fade-in-right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(500px);
            transform: translateX(500px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@-o-keyframes fade-in-right {
  0% {
    opacity: 0;
    -o-transform: translateX(500px);
       transform: translateX(500px);
  }
  100% {
    opacity: 1;
    -o-transform: translateX(0);
       transform: translateX(0);
  }
}
@keyframes fade-in-right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(500px);
         -o-transform: translateX(500px);
            transform: translateX(500px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
         -o-transform: translateX(0);
            transform: translateX(0);
  }
}
.fade-in-right {
  -webkit-animation-name: fade-in-right;
       -o-animation-name: fade-in-right;
          animation-name: fade-in-right;
}
@-webkit-keyframes fade-out-right {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(500px);
            transform: translateX(500px);
  }
}
@-o-keyframes fade-out-right {
  0% {
    opacity: 1;
    -o-transform: translateX(0);
       transform: translateX(0);
  }
  100% {
    opacity: 0;
    -o-transform: translateX(500px);
       transform: translateX(500px);
  }
}
@keyframes fade-out-right {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
         -o-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(500px);
         -o-transform: translateX(500px);
            transform: translateX(500px);
  }
}
.fade-out-right {
  -webkit-animation-name: fade-out-right;
       -o-animation-name: fade-out-right;
          animation-name: fade-out-right;
}
@-webkit-keyframes fade-in-right-sm {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@-o-keyframes fade-in-right-sm {
  0% {
    opacity: 0;
    -o-transform: translateX(100px);
       transform: translateX(100px);
  }
  100% {
    opacity: 1;
    -o-transform: translateX(0);
       transform: translateX(0);
  }
}
@keyframes fade-in-right-sm {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
         -o-transform: translateX(100px);
            transform: translateX(100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
         -o-transform: translateX(0);
            transform: translateX(0);
  }
}
.fade-in-right-sm {
  -webkit-animation-name: fade-in-right-sm;
       -o-animation-name: fade-in-right-sm;
          animation-name: fade-in-right-sm;
}
@-webkit-keyframes fade-out-right-sm {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
  }
}
@-o-keyframes fade-out-right-sm {
  0% {
    opacity: 1;
    -o-transform: translateX(0);
       transform: translateX(0);
  }
  100% {
    opacity: 0;
    -o-transform: translateX(100px);
       transform: translateX(100px);
  }
}
@keyframes fade-out-right-sm {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
         -o-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100px);
         -o-transform: translateX(100px);
            transform: translateX(100px);
  }
}
.fade-out-right-sm {
  -webkit-animation-name: fade-out-right-sm;
       -o-animation-name: fade-out-right-sm;
          animation-name: fade-out-right-sm;
}
@-webkit-keyframes fade-in-right-lg {
  0% {
    opacity: 0;
    -webkit-transform: translateX(1500px);
            transform: translateX(1500px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@-o-keyframes fade-in-right-lg {
  0% {
    opacity: 0;
    -o-transform: translateX(1500px);
       transform: translateX(1500px);
  }
  100% {
    opacity: 1;
    -o-transform: translateX(0);
       transform: translateX(0);
  }
}
@keyframes fade-in-right-lg {
  0% {
    opacity: 0;
    -webkit-transform: translateX(1500px);
         -o-transform: translateX(1500px);
            transform: translateX(1500px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
         -o-transform: translateX(0);
            transform: translateX(0);
  }
}
.fade-in-right-lg {
  -webkit-animation-name: fade-in-right-lg;
       -o-animation-name: fade-in-right-lg;
          animation-name: fade-in-right-lg;
}
@-webkit-keyframes fade-out-right-lg {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(1500px);
            transform: translateX(1500px);
  }
}
@-o-keyframes fade-out-right-lg {
  0% {
    opacity: 1;
    -o-transform: translateX(0);
       transform: translateX(0);
  }
  100% {
    opacity: 0;
    -o-transform: translateX(1500px);
       transform: translateX(1500px);
  }
}
@keyframes fade-out-right-lg {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
         -o-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(1500px);
         -o-transform: translateX(1500px);
            transform: translateX(1500px);
  }
}
.fade-out-right-lg {
  -webkit-animation-name: fade-out-right-lg;
       -o-animation-name: fade-out-right-lg;
          animation-name: fade-out-right-lg;
}
@-webkit-keyframes rotate-in {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transform-origin: center center;
            transform-origin: center center;
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: center center;
            transform-origin: center center;
  }
}
@-o-keyframes rotate-in {
  0% {
    opacity: 0;
    -o-transform: rotate(-90deg);
       transform: rotate(-90deg);
    -o-transform-origin: center center;
       transform-origin: center center;
  }
  100% {
    opacity: 1;
    -o-transform: rotate(0);
       transform: rotate(0);
    -o-transform-origin: center center;
       transform-origin: center center;
  }
}
@keyframes rotate-in {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
         -o-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transform-origin: center center;
         -o-transform-origin: center center;
            transform-origin: center center;
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
         -o-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: center center;
         -o-transform-origin: center center;
            transform-origin: center center;
  }
}
.rotate-in {
  -webkit-animation-name: rotate-in;
       -o-animation-name: rotate-in;
          animation-name: rotate-in;
}
@-webkit-keyframes rotate-out {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: center center;
            transform-origin: center center;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transform-origin: center center;
            transform-origin: center center;
  }
}
@-o-keyframes rotate-out {
  0% {
    opacity: 1;
    -o-transform: rotate(0);
       transform: rotate(0);
    -o-transform-origin: center center;
       transform-origin: center center;
  }
  100% {
    opacity: 0;
    -o-transform: rotate(90deg);
       transform: rotate(90deg);
    -o-transform-origin: center center;
       transform-origin: center center;
  }
}
@keyframes rotate-out {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
         -o-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: center center;
         -o-transform-origin: center center;
            transform-origin: center center;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
         -o-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transform-origin: center center;
         -o-transform-origin: center center;
            transform-origin: center center;
  }
}
.rotate-out {
  -webkit-animation-name: rotate-out;
       -o-animation-name: rotate-out;
          animation-name: rotate-out;
}
@-webkit-keyframes rotate-in-sm {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transform-origin: center center;
            transform-origin: center center;
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: center center;
            transform-origin: center center;
  }
}
@-o-keyframes rotate-in-sm {
  0% {
    opacity: 0;
    -o-transform: rotate(-45deg);
       transform: rotate(-45deg);
    -o-transform-origin: center center;
       transform-origin: center center;
  }
  100% {
    opacity: 1;
    -o-transform: rotate(0);
       transform: rotate(0);
    -o-transform-origin: center center;
       transform-origin: center center;
  }
}
@keyframes rotate-in-sm {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-45deg);
         -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transform-origin: center center;
         -o-transform-origin: center center;
            transform-origin: center center;
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
         -o-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: center center;
         -o-transform-origin: center center;
            transform-origin: center center;
  }
}
.rotate-in-sm {
  -webkit-animation-name: rotate-in-sm;
       -o-animation-name: rotate-in-sm;
          animation-name: rotate-in-sm;
}
@-webkit-keyframes rotate-out-sm {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: center center;
            transform-origin: center center;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transform-origin: center center;
            transform-origin: center center;
  }
}
@-o-keyframes rotate-out-sm {
  0% {
    opacity: 1;
    -o-transform: rotate(0);
       transform: rotate(0);
    -o-transform-origin: center center;
       transform-origin: center center;
  }
  100% {
    opacity: 0;
    -o-transform: rotate(45deg);
       transform: rotate(45deg);
    -o-transform-origin: center center;
       transform-origin: center center;
  }
}
@keyframes rotate-out-sm {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
         -o-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: center center;
         -o-transform-origin: center center;
            transform-origin: center center;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(45deg);
         -o-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transform-origin: center center;
         -o-transform-origin: center center;
            transform-origin: center center;
  }
}
.rotate-out-sm {
  -webkit-animation-name: rotate-out-sm;
       -o-animation-name: rotate-out-sm;
          animation-name: rotate-out-sm;
}
@-webkit-keyframes rotate-in-lg {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
    -webkit-transform-origin: center center;
            transform-origin: center center;
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: center center;
            transform-origin: center center;
  }
}
@-o-keyframes rotate-in-lg {
  0% {
    opacity: 0;
    -o-transform: rotate(-180deg);
       transform: rotate(-180deg);
    -o-transform-origin: center center;
       transform-origin: center center;
  }
  100% {
    opacity: 1;
    -o-transform: rotate(0);
       transform: rotate(0);
    -o-transform-origin: center center;
       transform-origin: center center;
  }
}
@keyframes rotate-in-lg {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-180deg);
         -o-transform: rotate(-180deg);
            transform: rotate(-180deg);
    -webkit-transform-origin: center center;
         -o-transform-origin: center center;
            transform-origin: center center;
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
         -o-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: center center;
         -o-transform-origin: center center;
            transform-origin: center center;
  }
}
.rotate-in-lg {
  -webkit-animation-name: rotate-in-lg;
       -o-animation-name: rotate-in-lg;
          animation-name: rotate-in-lg;
}
@-webkit-keyframes rotate-out-lg {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: center center;
            transform-origin: center center;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    -webkit-transform-origin: center center;
            transform-origin: center center;
  }
}
@-o-keyframes rotate-out-lg {
  0% {
    opacity: 1;
    -o-transform: rotate(0);
       transform: rotate(0);
    -o-transform-origin: center center;
       transform-origin: center center;
  }
  100% {
    opacity: 0;
    -o-transform: rotate(180deg);
       transform: rotate(180deg);
    -o-transform-origin: center center;
       transform-origin: center center;
  }
}
@keyframes rotate-out-lg {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
         -o-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: center center;
         -o-transform-origin: center center;
            transform-origin: center center;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(180deg);
         -o-transform: rotate(180deg);
            transform: rotate(180deg);
    -webkit-transform-origin: center center;
         -o-transform-origin: center center;
            transform-origin: center center;
  }
}
.rotate-out-lg {
  -webkit-animation-name: rotate-out-lg;
       -o-animation-name: rotate-out-lg;
          animation-name: rotate-out-lg;
}
@-webkit-keyframes flip-in-x {
  0% {
    opacity: 0;
    -webkit-transform: perspective(550px) rotateX(90deg);
            transform: perspective(550px) rotateX(90deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: perspective(550px) rotateX(0deg);
            transform: perspective(550px) rotateX(0deg);
  }
}
@-o-keyframes flip-in-x {
  0% {
    opacity: 0;
    transform: perspective(550px) rotateX(90deg);
  }
  100% {
    opacity: 1;
    transform: perspective(550px) rotateX(0deg);
  }
}
@keyframes flip-in-x {
  0% {
    opacity: 0;
    -webkit-transform: perspective(550px) rotateX(90deg);
            transform: perspective(550px) rotateX(90deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: perspective(550px) rotateX(0deg);
            transform: perspective(550px) rotateX(0deg);
  }
}
.flip-in-x {
  -webkit-animation-name: flip-in-x;
       -o-animation-name: flip-in-x;
          animation-name: flip-in-x;

  -webkit-backface-visibility: visible !important;
          backface-visibility: visible !important;
}
@-webkit-keyframes flip-out-x {
  0% {
    opacity: 1;
    -webkit-transform: perspective(550px) rotateX(0deg);
            transform: perspective(550px) rotateX(0deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: perspective(550px) rotateX(90deg);
            transform: perspective(550px) rotateX(90deg);
  }
}
@-o-keyframes flip-out-x {
  0% {
    opacity: 1;
    transform: perspective(550px) rotateX(0deg);
  }
  100% {
    opacity: 0;
    transform: perspective(550px) rotateX(90deg);
  }
}
@keyframes flip-out-x {
  0% {
    opacity: 1;
    -webkit-transform: perspective(550px) rotateX(0deg);
            transform: perspective(550px) rotateX(0deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: perspective(550px) rotateX(90deg);
            transform: perspective(550px) rotateX(90deg);
  }
}
.flip-out-x {
  -webkit-animation-name: flip-out-x;
       -o-animation-name: flip-out-x;
          animation-name: flip-out-x;

  -webkit-backface-visibility: visible !important;
          backface-visibility: visible !important;
}
@-webkit-keyframes flip-in-x-nr {
  0% {
    opacity: 0;
    -webkit-transform: perspective(100px) rotateX(90deg);
            transform: perspective(100px) rotateX(90deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: perspective(100px) rotateX(0deg);
            transform: perspective(100px) rotateX(0deg);
  }
}
@-o-keyframes flip-in-x-nr {
  0% {
    opacity: 0;
    transform: perspective(100px) rotateX(90deg);
  }
  100% {
    opacity: 1;
    transform: perspective(100px) rotateX(0deg);
  }
}
@keyframes flip-in-x-nr {
  0% {
    opacity: 0;
    -webkit-transform: perspective(100px) rotateX(90deg);
            transform: perspective(100px) rotateX(90deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: perspective(100px) rotateX(0deg);
            transform: perspective(100px) rotateX(0deg);
  }
}
.flip-in-x-nr {
  -webkit-animation-name: flip-in-x-nr;
       -o-animation-name: flip-in-x-nr;
          animation-name: flip-in-x-nr;

  -webkit-backface-visibility: visible !important;
          backface-visibility: visible !important;
}
@-webkit-keyframes flip-out-x-nr {
  0% {
    opacity: 1;
    -webkit-transform: perspective(100px) rotateX(0deg);
            transform: perspective(100px) rotateX(0deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: perspective(100px) rotateX(90deg);
            transform: perspective(100px) rotateX(90deg);
  }
}
@-o-keyframes flip-out-x-nr {
  0% {
    opacity: 1;
    transform: perspective(100px) rotateX(0deg);
  }
  100% {
    opacity: 0;
    transform: perspective(100px) rotateX(90deg);
  }
}
@keyframes flip-out-x-nr {
  0% {
    opacity: 1;
    -webkit-transform: perspective(100px) rotateX(0deg);
            transform: perspective(100px) rotateX(0deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: perspective(100px) rotateX(90deg);
            transform: perspective(100px) rotateX(90deg);
  }
}
.flip-out-x-nr {
  -webkit-animation-name: flip-out-x-nr;
       -o-animation-name: flip-out-x-nr;
          animation-name: flip-out-x-nr;

  -webkit-backface-visibility: visible !important;
          backface-visibility: visible !important;
}
@-webkit-keyframes flip-in-x-fr {
  0% {
    opacity: 0;
    -webkit-transform: perspective(1000px) rotateX(90deg);
            transform: perspective(1000px) rotateX(90deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: perspective(1000px) rotateX(0deg);
            transform: perspective(1000px) rotateX(0deg);
  }
}
@-o-keyframes flip-in-x-fr {
  0% {
    opacity: 0;
    transform: perspective(1000px) rotateX(90deg);
  }
  100% {
    opacity: 1;
    transform: perspective(1000px) rotateX(0deg);
  }
}
@keyframes flip-in-x-fr {
  0% {
    opacity: 0;
    -webkit-transform: perspective(1000px) rotateX(90deg);
            transform: perspective(1000px) rotateX(90deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: perspective(1000px) rotateX(0deg);
            transform: perspective(1000px) rotateX(0deg);
  }
}
.flip-in-x-fr {
  -webkit-animation-name: flip-in-x-fr;
       -o-animation-name: flip-in-x-fr;
          animation-name: flip-in-x-fr;

  -webkit-backface-visibility: visible !important;
          backface-visibility: visible !important;
}
@-webkit-keyframes flip-out-x-fr {
  0% {
    opacity: 1;
    -webkit-transform: perspective(1000px) rotateX(0deg);
            transform: perspective(1000px) rotateX(0deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: perspective(1000px) rotateX(90deg);
            transform: perspective(1000px) rotateX(90deg);
  }
}
@-o-keyframes flip-out-x-fr {
  0% {
    opacity: 1;
    transform: perspective(1000px) rotateX(0deg);
  }
  100% {
    opacity: 0;
    transform: perspective(1000px) rotateX(90deg);
  }
}
@keyframes flip-out-x-fr {
  0% {
    opacity: 1;
    -webkit-transform: perspective(1000px) rotateX(0deg);
            transform: perspective(1000px) rotateX(0deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: perspective(1000px) rotateX(90deg);
            transform: perspective(1000px) rotateX(90deg);
  }
}
.flip-out-x-fr {
  -webkit-animation-name: flip-out-x-fr;
       -o-animation-name: flip-out-x-fr;
          animation-name: flip-out-x-fr;

  -webkit-backface-visibility: visible !important;
          backface-visibility: visible !important;
}
@-webkit-keyframes flip-in-y {
  0% {
    opacity: 0;
    -webkit-transform: perspective(550px) rotateY(90deg);
            transform: perspective(550px) rotateY(90deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: perspective(550px) rotateY(0deg);
            transform: perspective(550px) rotateY(0deg);
  }
}
@-o-keyframes flip-in-y {
  0% {
    opacity: 0;
    transform: perspective(550px) rotateY(90deg);
  }
  100% {
    opacity: 1;
    transform: perspective(550px) rotateY(0deg);
  }
}
@keyframes flip-in-y {
  0% {
    opacity: 0;
    -webkit-transform: perspective(550px) rotateY(90deg);
            transform: perspective(550px) rotateY(90deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: perspective(550px) rotateY(0deg);
            transform: perspective(550px) rotateY(0deg);
  }
}
.flip-in-y {
  -webkit-animation-name: flip-in-y;
       -o-animation-name: flip-in-y;
          animation-name: flip-in-y;

  -webkit-backface-visibility: visible !important;
          backface-visibility: visible !important;
}
@-webkit-keyframes flip-out-y {
  0% {
    opacity: 1;
    -webkit-transform: perspective(550px) rotateY(0deg);
            transform: perspective(550px) rotateY(0deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: perspective(550px) rotateY(90deg);
            transform: perspective(550px) rotateY(90deg);
  }
}
@-o-keyframes flip-out-y {
  0% {
    opacity: 1;
    transform: perspective(550px) rotateY(0deg);
  }
  100% {
    opacity: 0;
    transform: perspective(550px) rotateY(90deg);
  }
}
@keyframes flip-out-y {
  0% {
    opacity: 1;
    -webkit-transform: perspective(550px) rotateY(0deg);
            transform: perspective(550px) rotateY(0deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: perspective(550px) rotateY(90deg);
            transform: perspective(550px) rotateY(90deg);
  }
}
.flip-out-y {
  -webkit-animation-name: flip-out-y;
       -o-animation-name: flip-out-y;
          animation-name: flip-out-y;

  -webkit-backface-visibility: visible !important;
          backface-visibility: visible !important;
}
@-webkit-keyframes flip-in-y-nr {
  0% {
    opacity: 0;
    -webkit-transform: perspective(100px) rotateY(90deg);
            transform: perspective(100px) rotateY(90deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: perspective(100px) rotateY(0deg);
            transform: perspective(100px) rotateY(0deg);
  }
}
@-o-keyframes flip-in-y-nr {
  0% {
    opacity: 0;
    transform: perspective(100px) rotateY(90deg);
  }
  100% {
    opacity: 1;
    transform: perspective(100px) rotateY(0deg);
  }
}
@keyframes flip-in-y-nr {
  0% {
    opacity: 0;
    -webkit-transform: perspective(100px) rotateY(90deg);
            transform: perspective(100px) rotateY(90deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: perspective(100px) rotateY(0deg);
            transform: perspective(100px) rotateY(0deg);
  }
}
.flip-in-y-nr {
  -webkit-animation-name: flip-in-y-nr;
       -o-animation-name: flip-in-y-nr;
          animation-name: flip-in-y-nr;

  -webkit-backface-visibility: visible !important;
          backface-visibility: visible !important;
}
@-webkit-keyframes flip-out-y-nr {
  0% {
    opacity: 1;
    -webkit-transform: perspective(100px) rotateY(0deg);
            transform: perspective(100px) rotateY(0deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: perspective(100px) rotateY(90deg);
            transform: perspective(100px) rotateY(90deg);
  }
}
@-o-keyframes flip-out-y-nr {
  0% {
    opacity: 1;
    transform: perspective(100px) rotateY(0deg);
  }
  100% {
    opacity: 0;
    transform: perspective(100px) rotateY(90deg);
  }
}
@keyframes flip-out-y-nr {
  0% {
    opacity: 1;
    -webkit-transform: perspective(100px) rotateY(0deg);
            transform: perspective(100px) rotateY(0deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: perspective(100px) rotateY(90deg);
            transform: perspective(100px) rotateY(90deg);
  }
}
.flip-out-y-nr {
  -webkit-animation-name: flip-out-y-nr;
       -o-animation-name: flip-out-y-nr;
          animation-name: flip-out-y-nr;

  -webkit-backface-visibility: visible !important;
          backface-visibility: visible !important;
}
@-webkit-keyframes flip-in-y-fr {
  0% {
    opacity: 0;
    -webkit-transform: perspective(1000px) rotateY(90deg);
            transform: perspective(1000px) rotateY(90deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: perspective(1000px) rotateY(0deg);
            transform: perspective(1000px) rotateY(0deg);
  }
}
@-o-keyframes flip-in-y-fr {
  0% {
    opacity: 0;
    transform: perspective(1000px) rotateY(90deg);
  }
  100% {
    opacity: 1;
    transform: perspective(1000px) rotateY(0deg);
  }
}
@keyframes flip-in-y-fr {
  0% {
    opacity: 0;
    -webkit-transform: perspective(1000px) rotateY(90deg);
            transform: perspective(1000px) rotateY(90deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: perspective(1000px) rotateY(0deg);
            transform: perspective(1000px) rotateY(0deg);
  }
}
.flip-in-y-fr {
  -webkit-animation-name: flip-in-y-fr;
       -o-animation-name: flip-in-y-fr;
          animation-name: flip-in-y-fr;

  -webkit-backface-visibility: visible !important;
          backface-visibility: visible !important;
}
@-webkit-keyframes flip-out-y-fr {
  0% {
    opacity: 1;
    -webkit-transform: perspective(1000px) rotateY(0deg);
            transform: perspective(1000px) rotateY(0deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: perspective(1000px) rotateY(90deg);
            transform: perspective(1000px) rotateY(90deg);
  }
}
@-o-keyframes flip-out-y-fr {
  0% {
    opacity: 1;
    transform: perspective(1000px) rotateY(0deg);
  }
  100% {
    opacity: 0;
    transform: perspective(1000px) rotateY(90deg);
  }
}
@keyframes flip-out-y-fr {
  0% {
    opacity: 1;
    -webkit-transform: perspective(1000px) rotateY(0deg);
            transform: perspective(1000px) rotateY(0deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: perspective(1000px) rotateY(90deg);
            transform: perspective(1000px) rotateY(90deg);
  }
}
.flip-out-y-fr {
  -webkit-animation-name: flip-out-y-fr;
       -o-animation-name: flip-out-y-fr;
          animation-name: flip-out-y-fr;

  -webkit-backface-visibility: visible !important;
          backface-visibility: visible !important;
}
@-webkit-keyframes zoom-in {
  0% {
    opacity: 0;
    -webkit-transform: scale(.7);
            transform: scale(.7);
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes zoom-in {
  0% {
    opacity: 0;
    -o-transform: scale(.7);
       transform: scale(.7);
  }
  100% {
    opacity: 1;
  }
}
@keyframes zoom-in {
  0% {
    opacity: 0;
    -webkit-transform: scale(.7);
         -o-transform: scale(.7);
            transform: scale(.7);
  }
  100% {
    opacity: 1;
  }
}
.zoom-in {
  -webkit-animation-name: zoom-in;
       -o-animation-name: zoom-in;
          animation-name: zoom-in;
}
@-webkit-keyframes zoom-out {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    opacity: 0;
    -webkit-transform: scale(.7);
            transform: scale(.7);
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes zoom-out {
  0% {
    opacity: 1;
    -o-transform: scale(1);
       transform: scale(1);
  }
  50% {
    opacity: 0;
    -o-transform: scale(.7);
       transform: scale(.7);
  }
  100% {
    opacity: 0;
  }
}
@keyframes zoom-out {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
         -o-transform: scale(1);
            transform: scale(1);
  }
  50% {
    opacity: 0;
    -webkit-transform: scale(.7);
         -o-transform: scale(.7);
            transform: scale(.7);
  }
  100% {
    opacity: 0;
  }
}
.zoom-out {
  -webkit-animation-name: zoom-out;
       -o-animation-name: zoom-out;
          animation-name: zoom-out;
}
@-webkit-keyframes zoom-in-sm {
  0% {
    opacity: 0;
    -webkit-transform: scale(.95);
            transform: scale(.95);
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes zoom-in-sm {
  0% {
    opacity: 0;
    -o-transform: scale(.95);
       transform: scale(.95);
  }
  100% {
    opacity: 1;
  }
}
@keyframes zoom-in-sm {
  0% {
    opacity: 0;
    -webkit-transform: scale(.95);
         -o-transform: scale(.95);
            transform: scale(.95);
  }
  100% {
    opacity: 1;
  }
}
.zoom-in-sm {
  -webkit-animation-name: zoom-in-sm;
       -o-animation-name: zoom-in-sm;
          animation-name: zoom-in-sm;
}
@-webkit-keyframes zoom-out-sm {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    opacity: 0;
    -webkit-transform: scale(.95);
            transform: scale(.95);
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes zoom-out-sm {
  0% {
    opacity: 1;
    -o-transform: scale(1);
       transform: scale(1);
  }
  50% {
    opacity: 0;
    -o-transform: scale(.95);
       transform: scale(.95);
  }
  100% {
    opacity: 0;
  }
}
@keyframes zoom-out-sm {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
         -o-transform: scale(1);
            transform: scale(1);
  }
  50% {
    opacity: 0;
    -webkit-transform: scale(.95);
         -o-transform: scale(.95);
            transform: scale(.95);
  }
  100% {
    opacity: 0;
  }
}
.zoom-out-sm {
  -webkit-animation-name: zoom-out-sm;
       -o-animation-name: zoom-out-sm;
          animation-name: zoom-out-sm;
}
@-webkit-keyframes zoom-in-lg {
  0% {
    opacity: 0;
    -webkit-transform: scale(.4);
            transform: scale(.4);
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes zoom-in-lg {
  0% {
    opacity: 0;
    -o-transform: scale(.4);
       transform: scale(.4);
  }
  100% {
    opacity: 1;
  }
}
@keyframes zoom-in-lg {
  0% {
    opacity: 0;
    -webkit-transform: scale(.4);
         -o-transform: scale(.4);
            transform: scale(.4);
  }
  100% {
    opacity: 1;
  }
}
.zoom-in-lg {
  -webkit-animation-name: zoom-in-lg;
       -o-animation-name: zoom-in-lg;
          animation-name: zoom-in-lg;
}
@-webkit-keyframes zoom-out-lg {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    opacity: 0;
    -webkit-transform: scale(.4);
            transform: scale(.4);
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes zoom-out-lg {
  0% {
    opacity: 1;
    -o-transform: scale(1);
       transform: scale(1);
  }
  50% {
    opacity: 0;
    -o-transform: scale(.4);
       transform: scale(.4);
  }
  100% {
    opacity: 0;
  }
}
@keyframes zoom-out-lg {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
         -o-transform: scale(1);
            transform: scale(1);
  }
  50% {
    opacity: 0;
    -webkit-transform: scale(.4);
         -o-transform: scale(.4);
            transform: scale(.4);
  }
  100% {
    opacity: 0;
  }
}
.zoom-out-lg {
  -webkit-animation-name: zoom-out-lg;
       -o-animation-name: zoom-out-lg;
          animation-name: zoom-out-lg;
}
.blink-slow {
  -webkit-animation-name: blink-slow;
       -o-animation-name: blink-slow;
          animation-name: blink-slow;
  -webkit-animation-duration: .3s;
       -o-animation-duration: .3s;
          animation-duration: .3s;
  -webkit-animation-timing-function: ease-in-out;
       -o-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
       -o-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
       -o-animation-direction: alternate;
          animation-direction: alternate;
}
@-webkit-keyframes blink-slow {
  0% {
    opacity: .4;
  }
  50% {
    opacity: .7;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes blink-slow {
  0% {
    opacity: .4;
  }
  50% {
    opacity: .7;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink-slow {
  0% {
    opacity: .4;
  }
  50% {
    opacity: .7;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes overlay-slide-in-top {
  0% {
    height: 100%;
  }
  100% {
    height: 0;
  }
}
@-o-keyframes overlay-slide-in-top {
  0% {
    height: 100%;
  }
  100% {
    height: 0;
  }
}
@keyframes overlay-slide-in-top {
  0% {
    height: 100%;
  }
  100% {
    height: 0;
  }
}
.overlay-slide-in-top {
  top: 0;
  height: 0;
  -webkit-animation-name: overlay-slide-in-top;
       -o-animation-name: overlay-slide-in-top;
          animation-name: overlay-slide-in-top;
}
@-webkit-keyframes overlay-slide-out-top {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}
@-o-keyframes overlay-slide-out-top {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}
@keyframes overlay-slide-out-top {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}
.overlay-slide-out-top {
  top: 0;
  height: 100%;
  -webkit-animation-name: overlay-slide-out-top;
       -o-animation-name: overlay-slide-out-top;
          animation-name: overlay-slide-out-top;
}
@-webkit-keyframes overlay-slide-in-bottom {
  0% {
    height: 100%;
  }
  100% {
    height: 0;
  }
}
@-o-keyframes overlay-slide-in-bottom {
  0% {
    height: 100%;
  }
  100% {
    height: 0;
  }
}
@keyframes overlay-slide-in-bottom {
  0% {
    height: 100%;
  }
  100% {
    height: 0;
  }
}
.overlay-slide-in-bottom {
  bottom: 0;
  height: 0;
  -webkit-animation-name: overlay-slide-in-bottom;
       -o-animation-name: overlay-slide-in-bottom;
          animation-name: overlay-slide-in-bottom;
}
@-webkit-keyframes overlay-slide-out-bottom {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}
@-o-keyframes overlay-slide-out-bottom {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}
@keyframes overlay-slide-out-bottom {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}
.overlay-slide-out-bottom {
  bottom: 0;
  height: 100%;
  -webkit-animation-name: overlay-slide-out-bottom;
       -o-animation-name: overlay-slide-out-bottom;
          animation-name: overlay-slide-out-bottom;
}
@-webkit-keyframes overlay-slide-in-left {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}
@-o-keyframes overlay-slide-in-left {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}
@keyframes overlay-slide-in-left {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}
.overlay-slide-in-left {
  width: 0;
  -webkit-animation-name: overlay-slide-in-left;
       -o-animation-name: overlay-slide-in-left;
          animation-name: overlay-slide-in-left;
}
@-webkit-keyframes overlay-slide-out-left {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@-o-keyframes overlay-slide-out-left {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@keyframes overlay-slide-out-left {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
.overlay-slide-out-left {
  left: 0;
  width: 100%;
  -webkit-animation-name: overlay-slide-out-left;
       -o-animation-name: overlay-slide-out-left;
          animation-name: overlay-slide-out-left;
}
@-webkit-keyframes overlay-slide-in-right {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}
@-o-keyframes overlay-slide-in-right {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}
@keyframes overlay-slide-in-right {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}
.overlay-slide-in-right {
  right: 0;
  width: 0;
  -webkit-animation-name: overlay-slide-in-right;
       -o-animation-name: overlay-slide-in-right;
          animation-name: overlay-slide-in-right;
}
@-webkit-keyframes overlay-slide-out-right {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@-o-keyframes overlay-slide-out-right {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@keyframes overlay-slide-out-right {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
.overlay-slide-out-right {
  right: 0;
  width: 100%;
  -webkit-animation-name: overlay-slide-out-right;
       -o-animation-name: overlay-slide-out-right;
          animation-name: overlay-slide-out-right;
}
