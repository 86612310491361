.page {
  padding: 20px;
}
.page-full {
  height: 100%;
  padding: 0;
}
.page-core {
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 60px 10px;
  background-color: $slategray;
  overflow: auto;
}
.form-search-header {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  .ant-form-item-control-input {
    max-width: 350px;
    justify-content: flex-end;
  }
  .ant-input-search {
    border-radius: 4px;
  }
  input {
    height: 35px;
  }
}

