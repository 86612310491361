.appWrapper.aside-fixed {
  #sidebar {
    position: fixed;
    top: 45px;
    bottom: 0;
  }
}

.appWrapper.aside-static {
  #wrap {
    position: relative;
    #controls {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;

      &.dropdown-open {
        right: 0;
      }
    }
  }
  #sidebar {
    position: static;
    float: left;
    padding-top: 45px;
    min-height: 100%;
  }
}

.appWrapper.aside-static.header-static {
  #sidebar {
    padding-top: 45px;
  }
}

#sidebar {
  width: 250px;
  padding: 0;
  background-color: $sidebar-bg;
  position: fixed;
  z-index: 21;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  -webkit-overflow-scrolling: -blackberry-touch;


  .panel-group {
    margin-bottom: 0;

    .panel + .panel {
      margin-top: 0;
    }

    .panel {
      border-radius: 0;
      border: 0;
      background-color: transparent;
      @include box-shadow(none);

      >.panel-heading {
        background-color: transparent;
        border: 0;
        text-transform: uppercase;
        padding: 0;

        .panel-title {
          margin: 0 15px;

          >a {
            display: block;
            position: relative;
            padding: 15px 15px 0;
            margin: 0 -15px;
            cursor: pointer;
            font-size: $font-size-mini;
            color: $transparent-white-2;
            text-decoration: none;

            &:after {
              content: '';
              display: block;
              border-bottom: 1px solid $transparent-white-2;
              margin-top: 15px;
            }

            &:hover {
              text-decoration: none;
              color: $transparent-white-5;
            }

            >i {
              position: absolute;
              right: 15px;
            }
          }
        }
        +.panel-collapse {
          >.panel-body {
            border-top: 0;
          }
        }
      }


      .panel-title {

        >a.collapsed {
          background-color: $transparent-black-05;
          color: $transparent-white-5;
          padding: 15px;

          &:after {
            display: none;
          }

          &:hover {
            color: white;
          }

          >i:before {
            content: "\f107";
          }
        }
      }

      .panel-body {
        color: $transparent-white-5;
      }
    }
  }

  .summary {
    .media {
      .media-body {
        font-size: $font-size-small;
        color: $transparent-white-3;
        text-transform: uppercase;

        .media-heading {
          font-weight: $font-weight-bold;
          color: $transparent-white-5;
        }
      }
    }
  }

  .settings {
    label {
      font-weight: $font-weight-normal;
    }

    .onoffswitch {
      right: -10px;

      .onoffswitch-label {
        @include opacity(.4);
        @include transition(all .2s ease-in-out);
      }
    }

    .onoffswitch-checkbox:checked + .onoffswitch-label {
      @include opacity(1);
    }
  }
}

#sidebar-wrap {
  -webkit-overflow-scrolling: touch;
  -webkit-overflow-scrolling: -blackberry-touch;
  width: 100%;
  height: 100%;
}

#navigation {
  margin: 0 -15px;
  padding: 0;
  -webkit-overflow-scrolling: touch;
  -webkit-overflow-scrolling: -blackberry-touch;
  list-style: none;
  background-color: $sidebar-bg;

  >li>a>i {
    display: inline-block;
    margin-right: 10px;
    text-align: center;
    font-size: $font-size-base;
    width: 20px;
    line-height: 1;
    @include transition(all .1s linear);
  }

  >li {
    position: relative;
    margin: 0;
    overflow: hidden;
    z-index: 10;

    &.open,
    &:hover {
      >a {
        background-color: $transparent-black-2;
        color: white;
      }
      >i {
        color: white;
      }
    }
    &.active {
      >a {
        background-color: $active-el;
        color: white;
      }
      >i {
        color: white;
      }

      .ink {
        z-index: 9;
      }
    }

    a {
      font-size: 13px;
      color: $link-color;
      @include transition(all .2s linear);
      @include user-select(none);
      position: relative;

      &:hover {
        text-decoration: none;
      }
    }

    .ink {
      display: block;
      position: absolute;
      background: $transparent-white-1;
      border-radius: 100%;
      transform: scale(0);
      width: 250px!important;
      height: 250px!important;
    }

    .ink.animate {
      @include animation(ripple 0.65s linear);
    }

    >a {
      font-weight: $font-weight-bold;
      position: relative;
      display: block;
      padding: 12px 15px;

      >.badge {
        position: absolute;
        top: 7px;
        left: 25px;
      }
      >.label {
        position: absolute;
        top: 13px;
        right: 10px;
      }
    }
  }

  .dropdown {

    >a>i:last-of-type {
      font-size: 10px;
      position: absolute;
      top: 17px;
      right: 0px;
    }

    &.open {
      >a>i:last-of-type:before {
        content: "\f068";
      }
    }

    >a {
      >.label {
        right: 35px;
      }
    }

    >ul {
      display: none;
      background-color: $transparent-black-15;
      list-style: none;
      padding: 5px 0;

      li {
        position: relative;
        padding: 0;

        &:hover,
        &.active {
          >a {
            color: $active-el;
          }
        }

        &:last-child > a {
          border-bottom: 0;
        }

        > a {
          font-size: $font-size-small;
          position: relative;
          display: block;
          padding: 8px 0 8px 30px;
          @include transition( all .2s linear);

          >i{
            margin-right: 15px;
          }

          >.badge,
          >.label {
            position: absolute;
            right: 10px;
            top: 8px;
          }
        }
      }
    }

    &.submenu {
      >a>i:last-of-type {
        top: 14px;
      }
      &.open {
        >a {
          color: white!important;
        }
      }
      >ul {
        background-color: transparent;
        padding-left: 15px;
      }
    }

  }
}



// /* width */
// ::-webkit-scrollbar {
//   width: 10px;
// }

// /* Track */
// ::-webkit-scrollbar-track {
//   background: #493d55;
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
//   background: #555;
//   border-radius: 10rem;
//   border: 1px solid #555;
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: #555;
// }
// ::-webkit-scrollbar-track-piece:start {
//   background: transparent;
// }
// ::-webkit-scrollbar-track-piece:end {
//   background: transparent;
// }

// /* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */

.scroll { overflow-y: scroll; }
// .overlay { overflow-y: overlay; }
// .overlay.custom:hover::-webkit-scrollbar { display: initial; }
// .overlay.custom::-webkit-scrollbar { display: none; }
// /* custom webkit scrollbars */
// .custom::-webkit-scrollbar { 
//     width: 10px; 
// }
// // .custom::-webkit-scrollbar-track { 
// //     background-color: transparent;
// //     border-width: 0;
// // }
// .custom::-webkit-scrollbar-thumb {
//     border-radius: 5px;
//     background-color: rgba(0,0,0,0.3);
//     border-style: solid;
//     border-color: transparent;
//     // border-width: 3px;
//     background-clip: padding-box;
// }
// /* hidden elements */
// .custom::-webkit-scrollbar-button,
// .custom::-webkit-scrollbar-track-piece,
// .custom::-webkit-scrollbar-corner,
// .custom::-webkit-resizer { display: none; }