@-webkit-keyframes littleFadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes littleFadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-20px, 0, 0);
    -ms-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
}

.littleFadeInLeft {
  -webkit-animation-name: littleFadeInLeft;
  animation-name: littleFadeInLeft;
}

@-webkit-keyframes littleFadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes littleFadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(20px, 0, 0);
    -ms-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
}

.littleFadeInRight {
  -webkit-animation-name: littleFadeInRight;
  animation-name: littleFadeInRight;
}

@-webkit-keyframes littleFadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes littleFadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    -ms-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
}

.littleFadeInUp {
  -webkit-animation-name: littleFadeInUp;
  animation-name: littleFadeInUp;
}

@-webkit-keyframes littleFadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes littleFadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    -ms-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
}

.littleFadeInDown {
  -webkit-animation-name: littleFadeInDown;
  animation-name: littleFadeInDown;
}

@-webkit-keyframes ripple {
  100% {opacity: 0; transform: scale(2.5);}
}

@keyframes ripple {
  100% {opacity: 0; transform: scale(2.5);}
}

.ripple {
  -webkit-animation-name: ripple;
  animation-name: ripple;
}

@-webkit-keyframes fadeOutText {
	0% { color: transparent; }
	80% { color: transparent; }
	100% { color: #fff; }
}

@-moz-keyframes fadeOutText {
	0% { color: transparent; }
	80% { color: transparent; }
	100% { color: #fff; }
}

@keyframes fadeOutText {
	0% { color: transparent; }
	80% { color: transparent; }
	100% { color: #fff; }
}

@-webkit-keyframes moveToRight {
	80% { -webkit-transform: translateX(350%); }
	81% { opacity: 1; -webkit-transform: translateX(350%); }
	82% { opacity: 0; -webkit-transform: translateX(350%); }
	83% { opacity: 0; -webkit-transform: translateX(-50%); }
	84% { opacity: 1; -webkit-transform: translateX(-50%);  }
	100% { -webkit-transform: translateX(0%); }
}

@-moz-keyframes moveToRight {
	80% { -moz-transform: translateX(350%); }
	81% { opacity: 1; -moz-transform: translateX(350%); }
	82% { opacity: 0; -moz-transform: translateX(350%); }
	83% { opacity: 0; -moz-transform: translateX(-50%); }
	84% { opacity: 1; -moz-transform: translateX(-50%);  }
	100% { -moz-transform: translateX(0%); }
}

@keyframes moveToRight {
	80% { transform: translateX(350%); }
	81% { opacity: 1; transform: translateX(350%); }
	82% { opacity: 0; transform: translateX(350%); }
	83% { opacity: 0; transform: translateX(-50%); }
	84% { opacity: 1; transform: translateX(-50%);  }
	100% { transform: translateX(0%); }
}

@-webkit-keyframes scaleUp {
	80% {
		opacity: 0;
		-webkit-transform: scale(2);
	}
	100% {
		opacity: 0;
		-webkit-transform: scale(2);
	}
}

@-moz-keyframes scaleUp {
	80% {
		opacity: 0;
		-moz-transform: scale(2);
	}
	100% {
		opacity: 0;
		-moz-transform: scale(2);
	}
}

@keyframes scaleUp {
	80% {
		opacity: 0;
		transform: scale(2);
	}
	100% {
		opacity: 0;
		transform: scale(2);
	}
}

@-webkit-keyframes fillToRight {
	to {
		width: 100%;
	}
}

@-moz-keyframes fillToRight {
	to {
		width: 100%;
	}
}

@keyframes fillToRight {
	to {
		width: 100%;
	}
}

@-webkit-keyframes emptyBottom {
	to {
		height: 100%;
	}
}

@-moz-keyframes emptyBottom {
	to {
		height: 100%;
	}
}

@keyframes emptyBottom {
	to {
		height: 100%;
	}
}

@-webkit-keyframes scaleFade {
	50% {
		opacity: 1;
		-webkit-transform: scale(1);
	}
	100% {
		opacity: 0;
		-webkit-transform: scale(2.5);
	}
}

@-moz-keyframes scaleFade {
	50% {
		opacity: 1;
		-moz-transform: scale(1);
	}
	100% {
		opacity: 0;
		-moz-transform: scale(2.5);
	}
}

@keyframes scaleFade {
	50% {
		opacity: 1;
		transform: scale(1);
	}
	100% {
		opacity: 0;
		transform: scale(2.5);
	}
}

@-webkit-keyframes dropDown {
	to {
		opacity: 1;
		-webkit-transform: scale(1);
	}
}

@-moz-keyframes dropDown {
	to {
		opacity: 1;
		-moz-transform: scale(1);
	}
}

@keyframes dropDown {
	to {
		opacity: 1;
		transform: scale(1);
	}
}

@-webkit-keyframes dropDownFade {
	50% {
		opacity: 1;
		-webkit-transform: scale(1);
	}
	100% {
		opacity: 0;
		-webkit-transform: scale(1.5);
	}
}

@-moz-keyframes dropDownFade {
	50% {
		opacity: 1;
		-moz-transform: scale(1);
	}
	100% {
		opacity: 0;
		-moz-transform: scale(1.5);
	}
}

@keyframes dropDownFade {
	50% {
		opacity: 1;
		transform: scale(1);
	}
	100% {
		opacity: 0;
		transform: scale(1.5);
	}
}

@-webkit-keyframes moveUp {
	0% {
		-webkit-transform: translateY(50%);
		opacity: 0;
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
	}
}

@-moz-keyframes moveUp {
	0% {
		-moz-transform: translateY(50%);
		opacity: 0;
	}
	100% {
		opacity: 1;
		-moz-transform: translateY(0);
	}
}

@keyframes moveUp {
	0% {
		transform: translateY(50%);
		opacity: 0;
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@-webkit-keyframes scaleFromUp {
	0% {
		-webkit-transform: scale(0);
		opacity: 0;
	}
	100% {
		opacity: 1;
		-webkit-transform: scale(1);
	}
}

@-moz-keyframes scaleFromUp {
	0% {
		-moz-transform: scale(0);
		opacity: 0;
	}
	100% {
		opacity: 1;
		-moz-transform: scale(1);
	}
}

@keyframes scaleFromUp {
	0% {
		transform: scale(0);
		opacity: 0;
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}


@-webkit-keyframes spinAround {
	from {
		-webkit-transform: rotate(0deg)
	}
	to {
		-webkit-transform: rotate(360deg);
	}
}
@-moz-keyframes spinAround {
	from {
		-moz-transform: rotate(0deg)
	}
	to {
		-moz-transform: rotate(360deg);
	}
}
@keyframes spinAround {
	from {
		transform: rotate(0deg)
	}
	to {
		transform: rotate(360deg);
	}
}

@-webkit-keyframes toRightFromLeft {
	49% {
		-webkit-transform: translate(100%);
	}
	50% {
		opacity: 0;
		-webkit-transform: translate(-100%);
	}
	51% {
		opacity: 1;
	}
}
@-moz-keyframes toRightFromLeft {
	49% {
		-moz-transform: translate(100%);
	}
	50% {
		opacity: 0;
		-moz-transform: translate(-100%);
	}
	51% {
		opacity: 1;
	}
}
@keyframes toRightFromLeft {
	49% {
		transform: translate(100%);
	}
	50% {
		opacity: 0;
		transform: translate(-100%);
	}
	51% {
		opacity: 1;
	}
}

@-webkit-keyframes toLeftFromRight {
	49% {
		-webkit-transform: translate(-100%);
	}
	50% {
		opacity: 0;
		-webkit-transform: translate(100%);
	}
	51% {
		opacity: 1;
	}
}
@-moz-keyframes toLeftFromRight {
	49% {
		-moz-transform: translate(-100%);
	}
	50% {
		opacity: 0;
		-moz-transform: translate(100%);
	}
	51% {
		opacity: 1;
	}
}
@keyframes toLeftFromRight {
	49% {
		transform: translate(-100%);
	}
	50% {
		opacity: 0;
		transform: translate(100%);
	}
	51% {
		opacity: 1;
	}
}

@-webkit-keyframes toTopFromBottom {
	49% {
		-webkit-transform: translateY(-100%);
	}
	50% {
		opacity: 0;
		-webkit-transform: translateY(100%);
	}
	51% {
		opacity: 1;
	}
}
@-moz-keyframes toTopFromBottom {
	49% {
		-moz-transform: translateY(-100%);
	}
	50% {
		opacity: 0;
		-moz-transform: translateY(100%);
	}
	51% {
		opacity: 1;
	}
}
@keyframes toTopFromBottom {
	49% {
		transform: translateY(-100%);
	}
	50% {
		opacity: 0;
		transform: translateY(100%);
	}
	51% {
		opacity: 1;
	}
}

@-webkit-keyframes toBottomFromTop {
	49% {
		-webkit-transform: translateY(100%);
	}
	50% {
		opacity: 0;
		-webkit-transform: translateY(-100%);
	}
	51% {
		opacity: 1;
	}
}
@-moz-keyframes toBottomFromTop {
	49% {
		-moz-transform: translateY(100%);
	}
	50% {
		opacity: 0;
		-moz-transform: translateY(-100%);
	}
	51% {
		opacity: 1;
	}
}
@keyframes toBottomFromTop {
	49% {
		transform: translateY(100%);
	}
	50% {
		opacity: 0;
		transform: translateY(-100%);
	}
	51% {
		opacity: 1;
	}
}

@-webkit-keyframes sonarEffect {
	0% {
		opacity: 0.1;
	}
	40% {
		opacity: 0.2;
		box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 5px 5px, 0 0 0 5px rgba(255,255,255,0.5);
	}
	100% {
		box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 5px 5px, 0 0 0 5px rgba(255,255,255,0.5);
		-webkit-transform: scale(1.5);
		opacity: 0;
	}
}
@-moz-keyframes sonarEffect {
	0% {
		opacity: 0.1;
	}
	40% {
		opacity: 0.2;
		box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 5px 5px, 0 0 0 5px rgba(255,255,255,0.5);
	}
	100% {
		box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 5px 5px, 0 0 0 5px rgba(255,255,255,0.5);
		-moz-transform: scale(1.5);
		opacity: 0;
	}
}
@keyframes sonarEffect {
	0% {
		opacity: 0.1;
	}
	40% {
		opacity: 0.2;
		box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 5px 5px, 0 0 0 5px rgba(255,255,255,0.5);
	}
	100% {
		box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 5px 5px, 0 0 0 5px rgba(255,255,255,0.5);
		transform: scale(1.5);
		opacity: 0;
	}
}

@-webkit-keyframes slit {
	50% { -webkit-transform: translateZ(-250px) rotateY(89deg); opacity: .5; -webkit-animation-timing-function: ease-out;}
	100% { -webkit-transform: translateZ(0) rotateY(0deg); opacity: 1; }
}

@-moz-keyframes slit {
	50% { -moz-transform: translateZ(-250px) rotateY(89deg); opacity: .5; -moz-animation-timing-function: ease-out;}
	100% { -moz-transform: translateZ(0) rotateY(0deg); opacity: 1; }
}

@keyframes slit {
	50% { transform: translateZ(-250px) rotateY(89deg); opacity: 1; animation-timing-function: ease-in;}
	100% { transform: translateZ(0) rotateY(0deg); opacity: 1; }
}
