#non-left-content {
  position: absolute;
  top: 45px;
  right: 0;
  bottom: 0;
  width: auto;
  left: 0;
  padding-left: 0;
  padding-top: 0;
  z-index: 1;
}

#header .branding {
  background-color: #493d55;
  width: 0px !important;
  height: 45px;
  float: left;
  padding: 0px !important;
}

#header .search {
  display: inline-flex;
  margin-left: 0px !important;
  position: relative;
  justify-content:space-between;
  height: 45px;
  width: calc(100% - 400px);
  @media (max-width: 960px) {
    background-color: #493d55;
    width: 100%;
  }
  .top-navigation {
    display: flex;
    align-items: center;
    flex-direction: row;
    // justify-content: center;
    .title {
      color: #fff;
      margin-left: 10px;
    }
    .navigation {
      display: flex;
    }
  }
}

@media (max-width: 480px) {
  .appWrapper.sidebar-xs #header .search {
    left: 0px;
  }
}
@media (max-width: 768px) {
  .appWrapper.sidebar-xs #header .search {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
}
#header .search:after {
  content: none;
}

#header .search .list-app {
  height: 45px;
  width: 53px;
  justify-content: center;
  align-items: center;
  display: flex;
  @media (min-width: 960px) {
    display: none;
  }
}
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0px;
}
@media (max-width: 768px) {
  .appWrapper.sidebar-xs #header .nav-right .nav-profile {
    position: absolute;
    bottom: 0px !important;
    right: 0px !important;
    top: 56px !important;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.avoid-click {
  pointer-events: none;
}
