.category-page {
  .ant-row.ant-form-item {
    width: 100%;
  }
  .form-action-header,
  .form-action-footer {
    .ant-form-item-control {
      max-width: 200px;
      width: 100%;
    }
  }
  .form-action-footer {
    margin-top: -30px;
  }
  .table-view {
    .amount-category {
      width: max-content;
      p {
        margin: 0;
      }
    }
  }
  .content-head {
    p {
      line-height: 1.5;
      margin: 0;
      font-size: 13px;
      color: #696969;
    }
  }
  .short-desciption {
    p {
      line-height: 1.5;
      margin: 0;
      font-size: 13px;
      font-style: italic;
      font-weight: 500;
      color: #8a8a8a;
    }
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    min-width: 24px;
    height: 24px;
    line-height: 1;
    font-size: 10px;
  }
  .ant-table-cell {
    position: relative;
    &:hover {
      .action-table {
        opacity: 1;
        height: auto;
        display: flex;
      }
    }
  }
  .action-table {
    transition: opacity 1s ease-out;
    opacity: 0;
    height: 0;
    overflow: hidden;
    .ant-btn {
      height: 22px;
      line-height: 1;
      padding: 2px 10px;
      font-size: 12px;
    }
    .btn-view {
      border-color: $button-view-color;
      color: $button-view-color;
      &:hover {
        background-color: $button-view-color;
        color: white;
      }
    }
    .btn-delete {
      border-color: $button-delete-color;
      color: $button-delete-color;
      &:hover {
        background-color: $button-delete-color;
        color: white;
      }
    }
    .btn-edit {
      border-color: $button-edit-color;
      color: $button-edit-color;
      &:hover {
        background-color: $button-edit-color;
        color: white;
      }
    }
  }
}
